.input-wrapper {
  position: relative;
}

.search-input {
  border: 1px solid #171717; /* Add your border styles here */
  padding: 5px;
  width: 90%;
}

.autocomplete {
  border: 1px solid #171717;
  padding: 5px;
  width: 100%;
  height: 3rem;
}

.search-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 100;
  display: none;
}

.search-suggestions li {
  padding: 5px;
  cursor: pointer;
}

.search-suggestions li:hover {
  background-color: #f0f0f0;
}

.input-wrapper:hover .search-suggestions {
  display: block;
}

.table-wrapper tr.centered-tr td {
  /* text-align: center; */
}

.table-wrapper table thead th {
  /* text-align: center; */
}
