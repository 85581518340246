html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  transition: 0.2ms all ease-out;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
a {
  text-decoration: none;
  color: #171717;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background-color: transparent;
  border: 0px;
  width: max-content;
  height: max-content;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

input,
input:focus,
select,
select:focus {
  border: 0px;
  outline: none;
  transition: color 0.2s ease;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.25);
  font-weight: 600;
}

.container {
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  overflow-x: hidden;
}

button,
a.button,
.input-wrapper-image label {
  font-weight: 500;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  cursor: pointer;
}

.body-wrapper {
  padding-top: 0px;
  background-color: #f0eded;
  width: calc(100vw - 250px);
  min-height: 100vh;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 50px;
}

@media only screen and (max-width: 1023px) {
  .body-wrapper {
    padding-top: 56px;
    width: 100vw;
  }
  .section {
    padding: 20px;
  }
}

.section .wrapper {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.table-wrapper {
  width: 100%;
  overflow: auto;
}

.table-header {
  display: flex;
  gap: 24px 40px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

.table-header h1 {
  font-weight: 700;
  font-size: 24px;
  color: #8e9195;
}

table {
  width: 100%;
}

table th,
table td {
  text-align: left;
  padding: 15px 20px;
  height: 50px;
  vertical-align: middle;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
}

table td.actions {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

table td a {
  color: #438bca;
  text-decoration: underline;
}

table td img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

table tbody tr:nth-child(2n) {
  background-color: rgba(241, 179, 41, 0.1);
}

table thead {
  background-color: #212121;
}

table th {
  color: #f1b429;
}

table td {
  color: #212121;
}

table td .flex {
  display: flex;
  width: max-content;
  align-items: center;
  gap: 8px;
}

table td.table-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 40px;
}

.table-wrapper.see-matches .table {
  height: 0px;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}

.table-wrapper.see-matches .table.active {
  height: 450px;
}

.table-wrapper.see-matches .table-header {
  justify-content: space-between;
}

button.danger {
  background-color: rgba(150, 13, 13, 0.714);
}

button.dark {
  background-color: #171717;
}

button.primary {
  background-color: #f1b429;
}

button.info,
label.info {
  background-color: #5cc0de;
}

button.success,
a.button.success {
  background-color: #348843c1;
}

button.secondary,
.input-wrapper-image label {
  background-color: #438bca;
}

button.secondary.disabled,
button.primary.disabled {
  background-color: #c9c8c8;
}

button.transparent {
  background-color: transparent;
  color: #171717;
  box-shadow: none;
  padding: 0px;
}

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
  display: none;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease-in;
}

.login-container {
  width: 100vw;
  overflow: hidden;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0eded;
  background: url(https://source.unsplash.com/1600x800/?nature=gold);
  background-repeat: no-repeat;
  background-size: cover;
}

.modal-container .modal-opener {
  padding-bottom: 20px;
}

.modal-container .modal-opener button {
  margin-left: auto;
}

.modal.active {
  display: flex;
}

.modal .wrapper,
.login-wrapper {
  width: calc(100vw - 20px);
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  max-width: 800px;
  background-color: #ffffff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border: 0px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.475rem;
  position: relative;
  padding: 0px;
}

.login-wrapper {
  max-width: 529px;
  height: 510px;
  padding: 20px 0px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
}

.modal .wrapper.full {
  max-width: 1600px;
}

.modal .wrapper .header {
  position: sticky;
  z-index: 100;
  background-color: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px 20px;
  padding: 25px 22.75px;
  border-bottom: 1px solid #f1f1f2;
}

.login-wrapper .header {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22.75px 20px 22.75px;
}

.login-wrapper .header .logo img {
  width: 200px;
}

.modal .wrapper.full .header {
  max-width: 1600px;
}

.modal .wrapper .header h2 {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
}

.modal .wrapper .content,
.login-wrapper .content {
  padding: 45.5px 39px;
  min-height: calc(100vh - 400px);
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.login-wrapper .content {
  gap: 20px;
}

.modal .wrapper .content .lists {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal .wrapper .content .lists li {
  color: #212121;
  font-size: 14px;
}

.modal .wrapper .content .form-section h4,
.form-section h4 {
  font-weight: 600;
  color: #8e9195;
  font-size: 20px;
  padding-bottom: 14px;
}

.form-section .flexed-images {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.form-section .flexed-images .image-wrapper {
  width: 240px;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.form-section .flexed-images .image-wrapper button {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(150, 13, 13, 0.714);
  height: 40px;
  width: 40px;
  box-shadow: none;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-section .flexed-images .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-section
  .flexed-images
  .input-wrapper
  .modal
  .wrapper
  .content
  .form-section.list
  h4 {
  padding-bottom: 0px;
}

.modal .wrapper .content .form-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .modal .wrapper .content .for */

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;
}

.input-wrapper.button {
  flex-direction: row;
}

.input-wrapper span {
  font-size: 16px;
  font-weight: 600;
  color: #8e9195;
}

.relocation {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-section .relocation .checkboxes {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.relocation .checkboxes label {
  text-align: left;
}

.flexed {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px 40px;
}

@media only screen and (min-width: 768px) {
  .flexed {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.m-r {
  margin: 24px 0;
}

.input-wrapper .checkboxes,
.form-section .checkboxes {
  display: flex;
  align-items: center;
  gap: 10px 40px;
  flex-wrap: wrap;
}

.input-wrapper .checkboxes input,
.form-section .checkboxes input {
  height: 13px;
  width: 13px;
}

.input-wrapper .checkboxes input[type="number"] {
  width: 40px;
}

.checkbox-wrapper {
  display: flex;
  gap: 2px;
  align-items: center;
}

.input-wrapper label,
.checkbox-wrapper label {
  color: #212121;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}

.input-wrapper-image {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.input-wrapper-image .image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border: 1px solid #c9c8c8;
  padding: 2px;
}

.input-wrapper-image .video {
  max-width: 250px;
  aspect-ratio: 4/5;
  width: 100%;
  overflow: hidden;
  border: 1px solid #c9c8c8;
  padding: 2px;
  position: relative;
}

.input-wrapper-image .video button {
  z-index: 20;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(150, 13, 13, 0.714);
  height: 40px;
  width: 40px;
  box-shadow: none;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-wrapper-image .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-wrapper-image .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-wrapper-image label {
  color: #ffffff;
}

.input-wrapper label .required {
  color: #f1416c;
}

.input-wrapper input,
.input-wrapper select {
  background-color: #f9f9f9;
  color: #171717;
  font-size: 13px;
  padding: 0.775rem 1rem;
}

.login-container .input-wrapper input,
.input-wrapper .primary {
  background-color: #dbdfe9;
}

.input-wrapper input:focus,
.input-wrapper select:focus {
  background-color: #dbdfe9;
}

.form-section ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form-section ul li {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 10px 10px 10px;
  border-radius: 3px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  position: relative;
}

.form-section ul li button.danger {
  padding: 5px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.form-section button.secondary {
  margin-left: auto;
  display: block;
  margin-top: 5px;
}

.form-section .preferences {
  display: flex;
  gap: 10px;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  /* grid-template-columns: repeat(auto-fit, 300px); */
}

.preferences.edit {
  flex-wrap: wrap;
  align-items: stretch;
  gap: 40px;
}

.form-section .preferences li {
  padding: 20px;
  gap: 20px;
  width: 300px;
  flex-shrink: 0;
  height: max-content;
}

.form-section .preferences.edit li {
  height: 100%;
}

.form-section .preferences.edit li .scorecards {
  padding: 0px;
}

.form-section .preferences li h5 {
  font-size: 16px;
  font-weight: 600;
  color: #8e9195;
}

.form-section .preferences li .options {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
}

.form-section .preferences li .scorecards {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.form-section .preferences li .scorecards button {
  box-shadow: none;
  color: #171717;
  border: 1px solid #d6d6d6;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 5px;
}

.form-section .preferences li .scorecards button.selected {
  background-color: #f1b429;
  border: 0px;
}

.form-section .preferences li .options .input-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
}

.password-hide-show-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  cursor: pointer;
}
.form-section .preferences li .scorecards,
.modal .wrapper .footer,
.login-wrapper .footer {
  padding: 20px 39px 20px 39px;
  position: sticky;
  bottom: 0px;
  z-index: 1000;
  background-color: #ffffff;
}

.login-wrapper .footer {
  background-color: transparent;
  padding-top: 0px;
}

.login-wrapper .footer button {
  padding: 13px 75px;
  margin: 0px auto;
}

.modal .wrapper .footer button {
  width: 100%;
  font-size: 16px;
  padding: 15px 30px;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  gap: 20px 50px;
  flex-wrap: wrap;
}

.filters .input-wrapper {
  max-width: 300px;
  width: 100%;
}
.filters .filters .input-wrapper select {
  border: solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border-width: 2px;
}

.filters .input-wrapper select:focus {
  border-color: #f1b429;
}

.loader-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  background-color: #0b0d10;
  z-index: 9999999;
}

.loader {
  width: 45px;
  height: 40px;
  background: linear-gradient(
      #0000 calc(1 * 100% / 6),
      #fff 0 calc(3 * 100% / 6),
      #0000 0
    ),
    linear-gradient(
      #0000 calc(2 * 100% / 6),
      #fff 0 calc(4 * 100% / 6),
      #0000 0
    ),
    linear-gradient(
      #0000 calc(3 * 100% / 6),
      #fff 0 calc(5 * 100% / 6),
      #0000 0
    );
  background-size: 10px 400%;
  background-repeat: no-repeat;
  animation: matrix 1s infinite linear;
}
@keyframes matrix {
  0% {
    background-position: 0% 100%, 50% 100%, 100% 100%;
  }
  100% {
    background-position: 0% 0%, 50% 0%, 100% 0%;
  }
}

.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-wrapper select {
  width: 100%;
  padding: 10px 40px 10px 10px; /* Adjust padding to make room for the icon */
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
}

.select-wrapper::after {
  content: "\25BC"; /* Unicode for down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Allow clicks to pass through */
  color: #555; /* Icon color */
}
