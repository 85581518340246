.tab-buttons {
  display: flex;
}

.tab-buttons button {
  padding: 20px 40px;
  cursor: pointer;
  border: none;
  /* color: #ccc; */
  background-color: #171717;
}

.tab-buttons button.active {
  background-color: #f1b329;
  color: white;
}

.tab-content {
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 10px;
}
